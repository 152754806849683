import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContextProvider';
import { SessionContext } from '../../../context/SessionContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { useHistory } from 'react-router-dom';
import { registerForEvent } from '../../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import SingleSelectInputFromList from '../common/SingleSelectInputFromList';
import TextBoxInput from '../common/TextBoxInput';
import CheckBoxInput from '../common/CheckBoxInput';
import { PrettyForm } from '../common/PrettyForm';
import PaymentReview from '../payment/PaymentReview';
import "./FormProcessor.css"
import Tooltip from '../../common/Tooltip';

/**
 * Reads json of a form template and generates an equivalent form
 * @param formJson        JSON file following form-schema.json
 * @param formModificationHandler  if non-null, the form is rendered in preview mode
 *                                 Supply a function that can react to events from the form
 *                                 The function should be of a type (event: String, idx: int) => {}.
 * @returns {JSX.Element}
 * @constructor
 */
const FormProcessor = ({
  formJson,
  formModificationHandler = null
}) => {
  const {
    id,
    name,
    includePaymentForm,
    elements,
  } = formJson;

  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();
  const isPreviewMode = !!formModificationHandler
  const onSubmit = isPreviewMode
    ? () => {}
    : () => withLoading(
      registerForEvent(authRetrievedProfile, formJson.id, session.paymentMethod, session),
      (res) => window.location.replace(res?.data),
      ALERT_ERROR_MESSAGE,
    );

  const Modifier = ({children, idx})=>{
    return (
      <div key={idx} style={{
        position: 'relative'
      }}>
        {children}
        <div className={'form-input-modifier form-input-up'} onClick={() => formModificationHandler('up', idx)}>
          <i className="bi bi-chevron-up" />
        </div>
        <div className={'form-input-modifier form-input-down'} onClick={() => formModificationHandler('down', idx)}>
          <i className="bi bi-chevron-down"/>
        </div>
        <div className={'form-input-modifier form-input-delete'} onClick={() => formModificationHandler('delete', idx)}>
          <i className="bi bi-x-lg"/>
        </div>

      </div>
    )

  }

  const formInputs = elements.map(element => {
    const {
      formInput,
      label,
      field,
      data
    } = element;
    switch (formInput) {
      case 'required-select-one':
        return <SingleSelectInputFromList label={label} field={field} data={data} required={true}/>;
      case 'select-one':
        return <SingleSelectInputFromList label={label} field={field} data={data} required={false}/>;
      case 'required-input-text':
        return <TextBoxInput label={label} field={field} required={true}/>;
      case 'input-text':
        return <TextBoxInput label={label} field={field} required={false}/>;
      case 'required-tick':
        return <CheckBoxInput label={label} field={field} required={true}/>;
      case 'tick':
        return <CheckBoxInput label={label} field={field} required={false}/>;
      case 'header':
        return <><h3>{label}</h3></>;
      default:
        return <p>Invalid input type requested: {formInput}</p>;
    }
  })
    .map(((element, idx) => {
      return formModificationHandler
        ? <Modifier key={idx} idx={idx}>
          {element}
        </Modifier>
        : <div key={idx}>{element}</div>;
    }));

  return (
    <PrettyForm
      submitText={includePaymentForm ? 'Proceed to pay' : 'Complete registration'}
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
      forPreview={formModificationHandler}
    >
      <h6 className={'display-4'}>{name}</h6>
      <br/>
      {includePaymentForm && <>
        <PaymentReview eventCode={id} isPreviewMode={isPreviewMode} />
      </>}
      {formInputs}
    </PrettyForm>
  );
};

export default FormProcessor;

import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useContext } from 'react';
import { EventContext } from '../../../../pages/EventViewPage';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { getRole, TEACHER_ROLE } from '../../../../utils/roleComputation';

export default function TeacherActionButton({className}) {
  const history = useHistory();
  const {eventCode} = useContext(EventContext);
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  if(getRole(auth) !== TEACHER_ROLE) return null
  const navigate = () => history.push(`/dashboard/registeredStudents/${eventCode}`);
  return (<a className={className} onClick={navigate}>Check students from your school</a>);
}

import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { EventContext } from '../../../../pages/EventViewPage';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';

const ViewFormButton = ({className})=>{
  const history = useHistory()
  const { form, eventCode } = useContext(EventContext);
  const {authRetrievedProfile: auth} = useContext(AuthContext);

  if(getRole(auth) !== ADMIN_ROLE) return null
  if(!form) return null

  return (<a className={className} onClick={()=>history.push(`/Payment/${eventCode}`)}>
      {" "} View form
  </a>)
}
export default ViewFormButton

import { useContext, useEffect } from 'react';
import ErrorPlaceholder from '../components/common/ErrorPlaceholder';
import Dashboard from '../components/events/Dashboard';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext, defaultPageMetadata } from '../context/PageContextProvider';
import {
  STUDENT_ROLE, TEACHER_ROLE,
} from '../utils/roleComputation';
import { StudentNewHereAccordion, TeacherNewHereAccordion } from '../components/dashboard/NewHereAccordion';
import FAQAccordion from '../components/dashboard/FAQAccordion';
import { Alert, Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function FAQPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  const history = useHistory();

  useEffect(() => {
    const pageMetadata = {
      titleLH: '',
      titleRH: "FAQ",
      body: 'Frequently asked questions'
    };
    setPageMetadata(pageMetadata);
  }, []);

  function UserView() {
    if (!authRetrievedProfile) {
      return <></>;
    }

    const { role } = authRetrievedProfile;

    if (role === STUDENT_ROLE) {
      return <StudentNewHereAccordion />;
    } if (role === TEACHER_ROLE) {
      return <TeacherNewHereAccordion />;
    }
    return <></>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
      </Breadcrumb>
      <FAQAccordion />
      <br/>
      <UserView />
    </>
  );
}


import axios from 'axios';

export const createEvent = (auth, session) => async () => await axios.post(`/events?email=${auth.email}&code=${auth.code}&defaultPrice=${session.defaultPrice}&isEQIDiscountEnabled=${session.isEQIDiscountEnabled || false}`, session);

export const getEvents = (auth) => async () => await axios.get(`/events?email=${auth?.email}&code=${auth?.code}`);
export const getEvent = (auth, eventCode) => async () => await axios.get(`/events/${eventCode}?email=${auth?.email}&code=${auth?.code}`);
export const getStudentsFromEvents = (auth, eventCode) => async () => await axios.get(`/events/${eventCode}/eventTickets?forEmail=${auth.email}&code=${auth.code}`);
export const releaseEvent = (auth, eventCode) => async () => {
  return await axios.post(`/events/${eventCode}/release?email=${auth.email}&code=${auth.code}`);
}

export const getRegistrationStatus = (auth, eventCode) => async () => await axios.get(`/events/${eventCode}/eventTickets/status?forEmail=${auth.email}&code=${auth.code}`);

export const getAllAvailableEventsNames = async () => await axios.get('/events/names');

export const registerForEvent = (auth, eventCode, paymentMethod, registrationForm) => async () => {
  const baseUrl = window.location.origin;

  // See backend's EventPaymentRequestDTO.java
  const paymentDto = {
    forEmail: auth.email,
    eventCode,
    paymentMethod,
    successUrl: `${baseUrl}/ThankYou`,
    failureUrl: `${baseUrl}/Failure`,
    cancelUrl: `${baseUrl}/Cancelled`,
    registrationForm,
  };

  return await axios.post(`/events/${eventCode}/eventTickets/register?email=${auth.email}&code=${auth.code}`, paymentDto);
};

export const addFormForEvent = (auth, eventCode, form) => async () => await axios.post(`/events/${eventCode}/forms?email=${auth.email}&code=${auth.code}`, form);

export const declineEvent = (auth, eventCode) => async () => await axios.post(`/events/${eventCode}/eventTickets/decline?forEmail=${auth.email}&code=${auth.code}`);

export const fetchPriceForEvent = (auth, eventCode) => async () => {
  if (!auth) {
    alert("You are not logged in.")
    return
  }
  return await axios.get(`/events/${eventCode}/price?forEmail=${auth.email}&code=${auth.code}&eventCode=${eventCode}`);
}

import React, { createContext, useState } from 'react';

const SessionContext = createContext({
  session: {},
  clearSession: () => {},
  handleSessionChange: (field) => (e) => {},
});

/**
 * This should only be used for forms that require a session context.
 * @param children
 * @returns {Element}
 * @constructor
 */
function SessionContextProvider({ children }) {
  const [session, setSession] = useState({});
  const clearSession = () => setSession({});

  const handleSessionChange = (attr, isCheckbox = false, directInput = false) => (e) => {
    const value = directInput ? e : (isCheckbox ? e.target.checked : e.target.value);
    const copy = { ...session, [attr]: value };
    if (['', undefined, null].includes(value)) delete copy[attr]; // Delete if empty - assumed by backend
    console.log(copy)
    setSession(copy);
  };

  return (
    <SessionContext.Provider value={{ session, clearSession, handleSessionChange }}>
      {children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionContextProvider };

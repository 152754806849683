import { useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { SessionContext } from '../../../context/SessionContextProvider';

export default function CheckBoxInput({ label, field, required = false }) {
  const { handleSessionChange } = useContext(SessionContext);

  const LabelWithAstrixIfRequired = ()=>{
    return (<>
      {label} {required && <span style={{ color: 'red' }}>{' *'}</span>}
    </>)
  }


  return (
    <Form.Check
      type="checkbox"
      label={LabelWithAstrixIfRequired()}
      onChange={handleSessionChange(field, true)}
      required={required}
    />
  );
}

import { Accordion } from 'react-bootstrap';

export default function Instructions({
  header,
  children
}) {
  return (
    <Accordion defaultActiveKey="-1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <b>{header}</b>
        </Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

import { Alert, Button, Col, Row } from 'react-bootstrap';
import { NavigatableTabs } from '../../dashboard/DashboardComponents';
import SelectOneBuilder from './builder/SelectOneBuilder';
import { useHistory, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import FormProcessor from './FormProcessor';
import TextInputBuilder from './builder/TextInputBuilder';
import TickBuilder from './builder/TickBuilder';
import HeaderBuilder from './builder/HeaderBuilder';
import Form from 'react-bootstrap/Form';
import { addFormForEvent } from '../../../services/events';
import { AuthContext } from '../../../context/AuthContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import './FormCreator.css'
import Tooltip from '../../common/Tooltip';
import { SessionContext } from '../../../context/SessionContextProvider';
import useSimpleModal from '../../../hooks/useSimpleModal';
import { toast } from 'react-toastify';

const FormCreator = ()=>{
  const { eventCode } = useParams();
  const [form, setForm] = useState({
    id: eventCode,
    name: "[Form title]",
    elements: []
  })
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const [loading, withLoading] = useWithLoading()
  const history = useHistory();
  const [setModalVisible, ModalWrapper] = useSimpleModal();

  const addElementToForm = (element)=>{
    setForm({
      ...form,
      elements: form.elements.concat(element)
    })
  }

  const addFormToEventHandler = () => withLoading(
    addFormForEvent(auth, eventCode, form),
    () => {
      toast.success("Form attached successfully!")
      history.push(`/dashboard/${eventCode}`)
    },
    ALERT_ERROR_MESSAGE
  )


  const formModificationEventHandler = (event, idx)=>{
    if (event === 'delete') {
      const left = form.elements.slice(0,idx);
      const right = form.elements.slice(idx+1);
      const newElements = left.concat(right);
      setForm({...form, elements: newElements});
    } else if (event === 'up' && idx !== 0) {
      const copy = [...form.elements]
      const tmp = copy[idx-1]
      copy[idx-1] = copy[idx]
      copy[idx] = tmp
      setForm({...form, elements: copy});
    } else if (event === 'down' && idx !== form.elements.length - 1) {
      const copy = [...form.elements]
      const tmp = copy[idx+1]
      copy[idx+1] = copy[idx]
      copy[idx] = tmp
      setForm({...form, elements: copy});
    }
  }

  return <>
    <h3 className={"display-6"}>Attach form for <b>#{eventCode}</b> <Tooltip tip="You can reorder/delete form input by pressing button that pops up when you hover the right side of each form input. Try adding your first form input! " /></h3>
    <Alert variant={"warning"}>
      <ul>
        <li>The form input here has some jitters when you type/tick. But don't worry about it; that won't be the case when it's used in actual forms.</li>
        <li>Price won't render here, but it will in the actual form based on the set price policy.</li>
      </ul>
    </Alert>
    <hr/>
    <Row>
      <Col lg={8}>
        <FormProcessor formJson={form} formModificationHandler={formModificationEventHandler} />
        <div className={'form-input-generator mt-3'} onClick={setModalVisible}>
          <i className="bi bi-plus-circle-dotted display-6 me-2" /> <span className={"lead"}>Add form input</span>
        </div>
        <ModalWrapper title={"Add form input"}>
          <NavigatableTabs tabs={{
            "Select One": <SelectOneBuilder addElementToForm={addElementToForm} />,
            "Input Text": <TextInputBuilder addElementToForm={addElementToForm} />,
            "Tick": <TickBuilder addElementToForm={addElementToForm} />,
            "Header": <HeaderBuilder addElementToForm={addElementToForm} />
          }}/>
        </ModalWrapper>
      </Col>
      <Col lg={4}>
        <Form.Group>
          <Form.Label column={"lg"}>
            Form Title
          </Form.Label>
          <Form.Control type={"text"} onChange={e => setForm({
            ...form,
            name: e.target.value
          })}/>
        </Form.Group>
        <Form.Group>
          <Form.Label column={"lg"}>
            Include payment form?
          </Form.Label>
          <Form.Check onChange={e => setForm({
            ...form,
            includePaymentForm: e.target.checked
          })} />
        </Form.Group>
        <br/>
        <span className={"lead"}>Observe Payload (Alpha)</span>
        <br/>
        <div style={{backgroundColor: "black", color: "white"}}>
          <div className={"p-2"}>
            <pre>{JSON.stringify(session, null, 2)}</pre>
          </div>
        </div>
        <br/><br/>
        <Button className={"me-3"} variant={"secondary"} onClick={() => history.push(`/dashboard/${eventCode}`)}>Add form later</Button>
        <Button onClick={addFormToEventHandler}>Attach form to event</Button>
      </Col>
    </Row>
  </>
}

export default FormCreator

import axios from 'axios';

export const getAllAnnouncements = async (email, code, getFirstN = 1000000) => axios.get(`/api/announcements?email=${email}&code=${code}&getFirstN=${getFirstN}`);

export const createAnnouncement = async ({ email, code, announcement }) => axios.post(`/api/announcements?email=${email}&code=${code}`, announcement);

export const getAnnouncementById = async (email, code, announcementId) => axios.get(`/api/announcements/${announcementId}?email=${email}&code=${code}`);

export const getAnnouncementByEventId = async (email, code, eventId) => axios.get(`/api/announcements/eventCode/${eventId}?email=${email}&code=${code}`);

export const addCommentToAnnouncement = async (email, code, announcementId, comment) => axios.post(`/api/announcements/${announcementId}/comment?email=${email}&code=${code}`, comment);

export const replyToComment = async (email, code, announcementId, parentIdAndReply) => axios.post(`/api/announcements/${announcementId}/comment/reply?email=${email}&code=${code}`, parentIdAndReply);

import { Link, useLocation } from 'react-router-dom';
import { Alert, Button, Container } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';
import { REGISTERED } from '../utils/Constants';

const pageMetadata = {
  titleLH: 'Thank',
  titleRH: 'You',
  body: 'Your registration/payment is now complete. Confirmation will be sent to your email shortly.',
};

export default function ThankYou() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return (
    <>
      <span className={"fs-4 bi bi-check-circle-fill text-success"}>{" "}Your registration is complete!</span><br/>
      <p className={"fs-5"}>
        Check the dashboard to confirm your registration status. You can do so by going into the event page. It may take up to 1 business day to process registration.
      </p>
      <p className="mt-3">
      </p>
      <Link to="/">
        <Button>Back to Dashboard</Button>
      </Link>
    </>
  );
}

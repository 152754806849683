import { useHistory, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../context/PageContextProvider';
import roundOneFormStructure from '../forms/NZPMC24R1.json'
import roundTwo from '../forms/NZPMC24R2.json'
import FormProcessor from '../components/forms/selfserve/FormProcessor';
import useWithLoading from '../hooks/useWithLoading';
import { getEvent } from '../services/events';
import { AuthContext } from '../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import Loading from '../components/common/Loading';

const pageMetadata = {
  titleLH: 'Registration Page for',
  titleRH: 'Event',
  body: 'Fill out the following form and proceed to the next page to make a payment',
};

export default function PaymentPage() {
  const history = useHistory();
  const { eventCode } = useParams();
  const { setPageMetadata } = useContext(PageContext);
  const [form, setForm] = useState()
  const [loading, withLoading] = useWithLoading()
  const {authRetrievedProfile: auth} = useContext(AuthContext);

  const getForm = () => withLoading(
    getEvent(auth, eventCode),
    (res) => setForm(res.data.form),
    ALERT_ERROR_MESSAGE
  )

  useEffect(() => {
    pageMetadata.titleRH = `#${eventCode}`;
    setPageMetadata(pageMetadata);
    if (!eventCode) history.push('/')
    getForm()
  }, []);

  return <Loading loading={loading}>
    {form && <FormProcessor formJson={form}/>}
  </Loading>
}



import { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PageContext } from '../context/PageContextProvider';
import { getAllAnnouncements } from '../services/announcement';
import CreateAnnouncementButton from '../components/events/contextbound/admin/CreateAnnouncementButton';
import Loading from '../components/common/Loading';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { AuthContext } from '../context/AuthContextProvider';
import ScrollSpyNavigation from '../components/common/layout/ScrollSpyNavigation.js'
import AnnouncementList from '../components/announcement/AnnouncementList';
import { categoriseByMonths } from '../utils/CategorisationUtils';
import { MONTH_LIST } from '../utils/Constants';

const pageMetadata = {
  titleLH: 'Our',
  titleRH: 'Announcements',
  body: "Don't miss any announcements! You need to refresh the page to see new comments.",
};

function AnnouncementListPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const history = useHistory();

  const [loading, withLoading] = useWithLoading();
  const [announcements, setAnnouncements] = useState([]);

  const { authRetrievedProfile } = useContext(AuthContext);
  const dateExtractor = (announcement) => announcement.creationDate;
  const announcementsByMonths = categoriseByMonths(announcements, dateExtractor);

  useEffect(() => {
    withLoading(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Announcements</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading} message="Retrieving all the announcements">
        <CategorisedNavigation list={announcementsByMonths} />
      </Loading>
    </>
  );
}


const CategorisedNavigation = ({list}) => {
    return (
      <Row>
        <Col md={12} lg={3}>
          <ScrollSpyNavigation
            list={list}
            labelRenderer={(category) => `${MONTH_LIST[category.month]}, ${category.year}`}
          />
        </Col>
        <Col md={12} lg={9}>
          {list.map(category => <>
            <div className={"lead my-3"} id={category.key}>{MONTH_LIST[category.month]}, {category.year}</div>
              <AnnouncementList announcements={category.list} detailedView />
          </>)}
        </Col>
      </Row>
    );
}

export default AnnouncementListPage;

import CommentForm from './CommentForm';
import Comment from './Comment';
import { useContext } from 'react';
import { AnnouncementContext } from '../../../pages/AnnouncementPage';
import { getNumberOfComments } from '../../../utils/AnnouncementUtils';

export default function CommentSection() {
  const {comments} = useContext(AnnouncementContext)
  return (
    <div className={'p-3'}>
      <CommentForm />
      <p className="lead fw-bold">{comments && getNumberOfComments(comments)}{' '}Comments</p>
      {comments?.map((comment) => (
        <Comment key={comment.id} comment={comment} topLevelComment/>))}
    </div>
  )
}

import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { ADMIN_ROLE } from '../../../../utils/roleComputation';

export default function CreateAnnouncementButton({className}) {
  const { authRetrievedProfile } = useContext(AuthContext);

  const history = useHistory();
  const moveToAdminPage = () => {
    history.push('/admin/createAnnouncement');
  };

  return authRetrievedProfile && authRetrievedProfile?.role === ADMIN_ROLE ? (
    <a className={className} onClick={moveToAdminPage}>
      {" "}Add Announcement
    </a>
  ) : null;
}

import { Alert, AlertLink } from 'react-bootstrap';
import DisplayForRole from '../components/common/DisplayForRole';
import { ADMIN_ROLE } from '../utils/roleComputation';
import January2025Update from '../components/releasenotes/2025/January2025Update';
import December2024Update from '../components/releasenotes/2024/December2024Update';

const ReleaseNotePage = () => {
  return (
    <>
      <DisplayForRole forRole={ADMIN_ROLE}>
        <h2><i className="bi bi-newspaper"/> 2025</h2>
        <January2025Update/>
      </DisplayForRole>
      <h2><i className="bi bi-newspaper"/> 2024</h2>
      <December2024Update/>
    </>
  );
};


export const Update = ({
  month,
  version,
  keyTheme,
  children,
  isUpcoming
}) => {
  return (<>
    <Alert variant={'secondary'} className={isUpcoming ? 'text-black-50' : 'text-black'}>
      <Alert.Heading>
        <p>
          {isUpcoming && '(Unconfirmed) '}{month}{' Release '}(Version: {version})
        </p>
      </Alert.Heading>
      <p>
        <b>Key theme</b>: {keyTheme}
      </p>
      <ol>
        {children}
      </ol>
    </Alert>
  </>);
};

export const UpdateSection = ({
  category,
  children
}) => {
  return (<>
    <li>{category}</li>
    <ul>
      {children}
    </ul>
  </>);
};



export default ReleaseNotePage;

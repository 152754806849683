import { toNZTString } from './DateTimeUtils';

export const getFormattedNZTDateString = (date) => {
  const options = {
    // weekday: 'short',
    // year: 'numeric',
    // month: 'short',
    // day: 'numeric',
    timeZoneName: undefined,
  };

  return toNZTString(date, options);
};

export const getDateStringFromAnnouncement = (scheduledDate, creationDate) => {
  const hasNoDateToRender = !scheduledDate && !creationDate;
  if (hasNoDateToRender) return '';

  const isScheduled = !!scheduledDate;
  if (isScheduled) {
    return `Scheduled ${getFormattedNZTDateString(scheduledDate)}`
  }
  console.log(creationDate)
  const [daysAgo, hoursAgo, millisAgo] = differenceFromNow(creationDate)

  if (millisAgo > 0) {
    if (daysAgo < 8) {
      if (hoursAgo < 48) return `${Number(hoursAgo.toFixed(0)) + 1} hour(s) ago`
      return `${Number(daysAgo.toFixed(0)) + 1} day(s) ago`;
    }
  }

  return getFormattedNZTDateString(creationDate);
};

export const removeHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const differenceFromNow = (date) => {
  const ago = new Date() - new Date(date)
  const agoInDays = ago/1000/60/60/24
  const agoInHours = ago/1000/60/60
  const agoInMillis = ago
  return [agoInDays, agoInHours, agoInMillis]
}


export function validateComment(comment) {
  if (!comment) {
    alert('You haven\'t written anything!');
    return false;
  }
  return true;
}

export function getNumberOfComments(comments) {
  let numComments = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const comment of comments) {
    numComments++;
    if (comment.replies && comment.replies.length > 0) {
      numComments += getNumberOfComments(comment.replies);
    }
  }
  return numComments;
}

import {
  forwardRef,
  useContext, useRef, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import { SessionContext } from '../../../context/SessionContextProvider';
import Loading from '../../common/Loading';
// eslint-disable-next-line import/no-cycle
import TextBoxInput from './TextBoxInput';

export function Section({ title, details, children }) {
  return (
    <>
      <h1 className="display-6">{title}</h1>
      <p className="lead">{details}</p>
      {children}
      <br />
    </>
  );
}

export function DatePickerInput({ label, field, required = true }) {
  const { handleSessionChange } = useContext(SessionContext);

  // for the date picker to actually show the date chosen
  const [date, setDate] = useState(null);

  const handleDateChange = (date) => {
    setDate(date);
    handleSessionChange(field, false, true)(date);
  };

  return (
    <PrettyFormGroup label={label}>
      <DatePicker
        onChange={handleDateChange}
        value={date}
        className="px-3"
        defaultValue={null}
        required={required}
      />
    </PrettyFormGroup>
  );
}

export const TextBoxInputWithButton = forwardRef((props, ref) => {
  const {
    label, field, handleClick, loading, required = true, children,
  } = props;
  const { session } = useContext(SessionContext);
  return (
    <>
      <TextBoxInput ref={ref} label={label} field={field} required={required} onEnter={() => handleClick(session[field])} />
      <Loading loading={loading}>
        <Button
          onClick={() => handleClick(session[field])}
          className="w-100"
          variant="outline-dark"
        >
          {children}
        </Button>
      </Loading>
    </>
  );
});

export function PrettyFormGroup({ label, children, required = false }) {
  return (
    <Form.Group className="my-3">
      <Form.Label>
        {label}
        {required && <span style={{ color: 'red' }}>{' *'}</span>}
      </Form.Label>
      {children}
    </Form.Group>
  );
}

/**
 * Autocomplete component.
 * @param label
 * @param field
 * @param data List of data to autocomplete
 * @returns {JSX.Element}
 * @constructor
 */
export function AutoCompleteTextBoxInput({
  data, field,
}) {
  const { handleSessionChange } = useContext(SessionContext);
  const schoolNameRef = useRef();
  return (
    <Typeahead
      style={{width: "100%", marginBottom: "8px"}}
      id="school-name-auto-suggest"
      onChange={(selectedArray) => {
        handleSessionChange(field, false, true)(selectedArray[0]);
      }}ccbtbhtblur
      onInputChange={(text) => {
        handleSessionChange(field, false, true)(text);
      }}
      ref={schoolNameRef}
      options={data}
    />
  );
}

import { useContext, useEffect, useState } from 'react';
import useWithLoading from '../../hooks/useWithLoading';
import Loading from '../common/Loading';
import { getStudentsOfSchool } from '../../services/schools';
import { AuthContext } from '../../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import './SchoolStudentsView.css'
import { Col, Row } from 'react-bootstrap';

export default function SchoolStudentsView(){
  const {authRetrievedProfile:auth} = useContext(AuthContext)
  const [loading, withLoading] = useWithLoading();
  const [students, setStudents] = useState([])

  useEffect(() => {
   withLoading(
     getStudentsOfSchool(auth),
     res => setStudents(res.data),
     ALERT_ERROR_MESSAGE
   )
  }, []);

  const StudentCard = ({student})=>{
    const {
      gender,
      creationDate,
      name
    } = student
    return (
      <Col lg={3} md={4} sm={6} xs={12}>
        <div key={name} className={`student-card ${gender === "Male" && "male"} ${gender === "Female" && "female"}`}>
          <span>{name}</span> <br/>
          <small className={"text-muted"}>Joined in {new Date(creationDate).getFullYear()}</small>
        </div>
      </Col>
    )
  }

  const StudentCardList = ({yearLevel, students}) => {
    return (
      <>
        <div className={"fs-4 m-2"}>Year {yearLevel}</div>
        {students.map(e => <StudentCard student={e} />)}
      </>
    )
  }

  const yearLevelToStudents = Object.groupBy(students, student => student.yearLevel)

  return (<Loading loading={loading}>
    <Row>
      {Object.keys(yearLevelToStudents).map(yearLevel => <StudentCardList yearLevel={yearLevel} students={yearLevelToStudents[yearLevel]} />)}
    </Row>
  </Loading>)
}

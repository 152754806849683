import { createContext, useContext, useEffect, useState } from 'react';
import useWithLoading from '../../hooks/useWithLoading';
import { getSchool } from '../../services/schools';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import { Col, Row } from 'react-bootstrap';
import Loading from '../common/Loading';
import './SchoolViewPage.css'
import { toast } from 'react-toastify';

const SchoolContext = createContext({
  name: "",
  street: "",
  suburb: "",
  city: "",
  region: "",
  island: "",
  postcode: "",
  eqi: 0,
  latitude: 0.0,
  longitude: 0.0,
  schoolWebsite: "",
  schoolEmail: "",
  schoolPhone: "",
  teachers: [],
  isCustomSchool: false,
  // additionally fetched
  students: []
})

const SchoolViewPage = ({schoolName}) => {
  if(!schoolName) return null;
  const [school, setSchool] = useState({});
  const [loading, withLoading] = useWithLoading();

  useEffect(() => {
    withLoading(
      getSchool(schoolName),
      res => setSchool(res.data),
      ALERT_ERROR_MESSAGE
    )
  }, [schoolName]);

  return(
    <Loading loading={loading}>
      <SchoolContext.Provider value={{ ...school }}>
        <div className={"school-view-page"}>
          <Row>
            <Col md={12}>
              <SchoolDetails/>
              <SchoolTeacherInfo/>
            </Col>
            <Col md={12}>
              <SchoolMapView/>
            </Col>
          </Row>
        </div>
      </SchoolContext.Provider>
    </Loading>
  )
}

const SchoolDetails = () => {
  const {name, street, suburb, city, region, island, postcode} = useContext(SchoolContext)
  return(
    <>
      <span className={'fs-4'}>{name}<br/></span>
      {street && <span>{street}<br/></span>}
      {suburb && <span>{suburb},{" "}</span>}
      {city && <span>{city}<br/></span>}
      {region && <span>{region},{" "}</span>}
      {postcode && <span>{postcode}<br/></span>}
      {island && <span>{island}<br/></span>}
    </>
  )
}

const SchoolTeacherInfo = () => {
  const {teachers} = useContext(SchoolContext)

  const teachersExist = teachers && Array.isArray(teachers) && teachers.length > 0

  return (
    <>
      {teachersExist &&
        <span className={'fs-5'}>
          <br/>
          <span className={'teachers'}>
            <i className="bi bi-person-check me-1"></i>Teacher Contact
          </span><br/>
          <span className={'fs-6'}>{teachers.join(', ')}</span>
        </span>}
    </>
  )
}

const SchoolMapView = () => {

  const {
    longitude,
    latitude
  } = useContext(SchoolContext);

  if(!longitude || !latitude) return <>School does not have a specified location</>

  const mapString = `https://www.openstreetmap.org/export/embed.html?bbox=${longitude - 0.01}%2C${latitude - 0.01}%2C${longitude + 0.01}%2C${latitude + 0.01}&amp;layer=mapnik&amp;mlon=${longitude}&amp;mlat=${latitude}`
  console.log("hi", mapString)

  return (
    <>
      {(latitude && longitude) && <>
        <iframe width="100%" height="300px"
                src={mapString}
                style={{ border: "1px solid black" }}></iframe>
        <br/><small><a href="https://www.openstreetmap.org/?mlat=-26.015&amp;mlon=137.659#map=8/-26.015/137.659">View
        Larger Map</a></small>
      </>}
    </>
  )
}

export default SchoolViewPage
export {
  SchoolContext
}

import { useContext, useEffect, useState } from 'react';
import { EventContext } from '../../../pages/EventViewPage';
import useWithLoading from '../../../hooks/useWithLoading';
import { getAnnouncementByEventId } from '../../../services/announcement';
import { AuthContext } from '../../../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import AnnouncementList from '../../announcement/AnnouncementList';
import Loading from '../../common/Loading';

const EventAnnouncementsDisplay = () => {
  const { eventCode } = useContext(EventContext)
  const [loading, withLoading] = useWithLoading()
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  const [announcements, setAnnouncements] = useState([])

  useEffect(() => withLoading(
    () => getAnnouncementByEventId(auth?.email, auth?.code, eventCode),
    res => setAnnouncements(res.data),
    ALERT_ERROR_MESSAGE
  ), []);

  return <Loading loading={loading} message={"Loading Announcements"}>
    {announcements.length > 0 && <p className={"fw-bold text-muted small mb-2"}>Announcements</p> }
    {announcements.length > 0 && <AnnouncementList announcements={announcements} detailedView />}
    {announcements.length === 0 && <span>No announcements to show</span>}
  </Loading>
}

export default EventAnnouncementsDisplay

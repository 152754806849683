import { Section } from '../components/forms/common/FormComponents';

import FormCreator from '../components/forms/selfserve/FormCreator';

const CreateFormPage = ()=>{

  return(
    <FormCreator />
  )
}

export default CreateFormPage

import { Update, UpdateSection } from '../../../pages/ReleaseNotePage';

const December2024Update = () => {
  return (<Update month={'December'} version={'1.0.0'}
                  keyTheme={'Massive UI upgrade & performance improvement & registration process revamp'}>
    <UpdateSection category={"Registration Process"}>
      <li>You can create a platform account without associating yourself to a school. This applies to both teachers
        and students
      </li>
      <li>Multiple teachers can be associated with a school</li>
      <li>You can join a school once you create an account. Existing students and teachers have automatically been added to all schools</li>
      <li>From 2025, you will be able to sit a competition without joining a school. This will be implemented as part of the next release</li>
      <li>My school page shows fellow NZPMCians (students, teachers) from your school</li>
    </UpdateSection>
    <UpdateSection category={'Announcement and event'}>
      <li>Announcement page shows comments on the right, with sub-comments collapsed by default</li>
      <li>Event page is much better organised with clearer sections. In particular, available 'actions' are all standardised as links, location/event date/registration date is displayed in a box, and it clearly indicates on the top if the event is registrable.</li>
      <li>Announcement list page and events list page are now categorised by each month, and navigation aids
        scrolling
        user's page.
      </li>
      <li>Events and announcements are all public, so you can see them easily without logging in.</li>
      <li>Dates/time within 3 days are displayed as 'X days ago' or 'Y hours ago'</li>
      <li>In list view, each event/announcement item is concise; uses clear icons & colours.</li>
    </UpdateSection>
    <UpdateSection category={'Mobile view'}>
      <li>Mobile view now gets a slimmer top pane for content real estate.</li>
      <li>Pages in general take account for mobile view (eg - two-column view collapses into singular column view). Most of them already did.</li>
    </UpdateSection>
    <UpdateSection category={"Landing Page"}>
      <li>Landing page is ever more informative yet concise</li>
      <li>Shows recent announcements & events. Each list/item is concise.</li>
      <li>Upcoming events are shown separate from past events of current year</li>
      <li>'Go to' section collates noteworthy links</li>
    </UpdateSection>
    <UpdateSection category={'General User Experience'}>
      <li>Blocking pop-up alerts have been removed for smoother navigation experience.</li>
      <li>Clicking logo leads to landing page instead of the official website</li>
      <li>Separate button exists on the top navigation bar for going into Official Websites</li>
      <li>Icons, icons, icons</li>
    </UpdateSection>
    <UpdateSection category={'Nerd-ier update'}>
      <li>Massive performance improvement (8 -> 1s load time) by moving MongoDB closer to our backend (Heroku)
        instance (from ap-east-2 to us-east-1).
      </li>
      <li>Modularised codebase using Gradle modules</li>
      <li>School, Announcement, Event view page have components whose props are passed through React Context</li>
      <li>In general, things are designed in such a way that non-logged in users can view many information (eg announcements, events, dashboard). Visual clutter was drastically reduced that would prevent new users.</li>
    </UpdateSection>
  </Update>);
};
export default December2024Update

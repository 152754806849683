
export function categoriseByMonths(list, dateExtractor) {
  // List<tuple(key, year, month, list)>
  const unorderedMap = Object.values(list.reduce((result, item) => {
    const date = new Date(dateExtractor(item));
    const year = date.getFullYear()
    const month = date.getMonth()

    const key = `${year}_${month}`;
    result[key] = result[key] || {key, month, year, list: []};
    result[key].list.push(item);
    return result;
  },{}))

  // sort in descending order of year_month
  const orderedMap = unorderedMap.sort((a,b) => {
    if (b.year !== a.year) {
      return (b.year - a.year)
    }
    return b.month - a.month
  }) || []

  return orderedMap
}

import Form from 'react-bootstrap/Form';
import { useContext, useEffect, useState } from 'react';
import FormSubmissionButton from './FormSubmissionButton';
import { SessionContext } from '../../../context/SessionContextProvider';

/**
 * On form loading, it will internally reset session so that it's fresh new for each form submission.
 * On form submission, it will internally validate form and not call onSubmit if the form is invalid.
 *
 * You must externally manage loading and pass loading state to the form.
 * useWithLoading() hook is probably helpful (see usage)
 *
 * @param onSubmit This is a function that gets called on submit button press
 * @param onCancel This is usually a navigation function (for example, go back to previous page)
 * @param submitText Submit button text
 * @param cancelText Cancel button text
 * @param loading Loading state from outside PrettyForm. This will be used to block user from double form submission
 * @param cancelButtonExists (default=true) You have choice on having 'cancel' button on the form
 * @param forPreview if truth-y, you will not see buttons
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function PrettyForm(
  {
    submitText, onSubmit, cancelButtonExists = true, cancelText, onCancel, loading, forPreview, children,
  },
) {
  // reset form on load, assume we are starting a new form
  const { clearSession } = useContext(SessionContext);
  useEffect(() => clearSession(), []);
  const [validate, setValidate] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidated = formHandler(e);
    if (!isValidated) return;
    setValidate(true);
    onSubmit();
  };

  /**
     * Supports Form Validation functionality
     * @param event
     * @returns {boolean} True if the form has all required input, else False
     */
  const formHandler = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return false;
    }
    return true;
  };

  return (
    <Form validated={validate} onSubmit={handleSubmit}>
      {children}
      {!forPreview && <FormSubmissionButton
        loading={loading}
        textForSubmit={submitText}
        onCancel={onCancel}
        textForCancel={cancelText}
        cancelButtonExists={cancelButtonExists}
      />}
    </Form>
  );
}

import { useContext, useRef, useState } from 'react';
import { AuthContext } from '../../../context/AuthContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { addCommentToAnnouncement } from '../../../services/announcement';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import Loading from '../../common/Loading';
import { Alert, Button, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AnnouncementContext } from '../../../pages/AnnouncementPage';
import { validateComment } from '../../../utils/AnnouncementUtils';

export default function CommentForm() {
  const { addComment } = useContext(AnnouncementContext)
  const [comment, setComment] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const ref = useRef();

  const handleChange = (e) => setComment(e?.target?.value);

  if(!auth) return <Alert variant="dark">Comments are currently disabled. To add a comment, please login!</Alert>

  return (
    <InputGroup className="mb-3">
      <Form.Control ref={ref} placeholder="Enter your comment" onChange={handleChange}/>
      <Button variant="outline-secondary" onClick={()=>addComment(comment)}>
        Submit
      </Button>
    </InputGroup>
  )
}

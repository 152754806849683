import { useContext, useEffect, useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContextProvider';
import { SessionContext } from '../../../context/SessionContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { authenticate, sendCode } from '../../../services/authroutes';
import { ALERT_ERROR_MESSAGE, ALERT_RES_DATA } from '../../../utils/ResponseUtils';
import { TextBoxInputWithButton } from '../common/FormComponents';
import { toast } from 'react-toastify';

export default function LoginInput() {
  const { session } = useContext(SessionContext);
  const { setAuthRetrievedProfile } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  const emailInput = useRef();
  const codeInput = useRef();
  useEffect(() => emailInput.current.focus(), []);

  const onSendCode = () => {
    withLoading(sendCode(session), ALERT_RES_DATA, ALERT_ERROR_MESSAGE);
    codeInput.current.focus();
  };

  /**
     * When user writes email/code to login page, this is saved into session (from SessionContext).
     * This function pastes that into authRetrievedProfile, which is persisted into localStorage
     *
     * @returns {*}
     */
  const onLogIn = () => withLoading(
    authenticate(session),
    (res) => {
      setAuthRetrievedProfile({
        ...res?.data,
        email: session.email,
        code: session.code,
      });
      console.log("hi", res.data)
      toast.success(`Logged in successfully as ${session.email}`)
      history.push('/');
    },
    ALERT_ERROR_MESSAGE,
  );

  return (
    <>
      <Alert>
        <Alert.Heading>
          If you are new...
        </Alert.Heading>
        Please create an account using following buttons, login. Don't forget to actually register for the event, too.
        <br />
        <br />
        <Button variant="success" onClick={() => history.push('/teacher')}>I'm a Teacher</Button>
        {' '}
        {' '}
        <Button variant="success" onClick={() => history.push('/student')}>I'm a Student</Button>
      </Alert>
      <TextBoxInputWithButton
        label="Your Email"
        field="email"
        handleClick={onSendCode}
        loading={loading}
        ref={emailInput}
      >
        Send Code
      </TextBoxInputWithButton>
      <TextBoxInputWithButton
        label="Email Validation Code"
        field="code"
        handleClick={onLogIn}
        loading={loading}
        ref={codeInput}
      >
        Log in!
      </TextBoxInputWithButton>
    </>
  );
}

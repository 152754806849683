import axios from 'axios';


export const getSchoolNames = () => async () => axios.get(`/api/schools/names`);

export const getSchool = (schoolName) => async () => axios.get(`/api/school/${schoolName}`);

export const getStudentsOfSchool = (auth) => async () => axios.get(`/api/school/${auth.school}/students?email=${auth.email}&code=${auth.code}`);


export const joinSchoolAsStudent = (auth, schoolName) => async () => axios.post(`/api/school/${schoolName}/students?email=${auth.email}&code=${auth.code}`);

export const joinSchoolAsTeacher = (auth, schoolName) => async () => axios.post(`/api/school/${schoolName}/teachers/main?email=${auth.email}&code=${auth.code}`);


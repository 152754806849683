import useWithLoading from '../../../../hooks/useWithLoading';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { useHistory } from 'react-router-dom';
import { EventContext } from '../../../../pages/EventViewPage';
import { releaseEvent } from '../../../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import Loading from '../../../common/Loading';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';

export default function ReleaseEvent({className}) {
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile:auth } = useContext(AuthContext);
  const history = useHistory();
  const eventContext = useContext(EventContext)
  const {isReleased, eventCode} = eventContext

  console.log('hi', eventContext)

  if(isReleased) return null;
  if(getRole(auth) !== ADMIN_ROLE) return null


  const onClick = () => {
    withLoading(
      releaseEvent(auth, eventCode),
      (res) => {
        alert('Event released for registration!');
        // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
        history.go(0);
      },
      ALERT_ERROR_MESSAGE,
    );
  };


  return (
    <Loading loading={loading}>
      <a className={className} onClick={onClick}>
        {" "} Release event
      </a>
    </Loading>
  );
}

import { getDateStringFromAnnouncement } from '../../../utils/AnnouncementUtils';
import RichTextDisplay from '../../forms/common/richtext/RichTextDisplay';
import { PdfDisplayer } from '../../common/PdfComponents';
import { useContext } from 'react';
import { AnnouncementContext } from '../../../pages/AnnouncementPage';

export default function AnnouncementContent() {
  const {title, body, scheduledDate, creationDate, attachmentURLs} = useContext(AnnouncementContext)

  return (
    <div className="border p-3">
      <h1 className="display-6">{title ?? 'Untitled'}</h1>
      <p className="text-end">{getDateStringFromAnnouncement(scheduledDate, creationDate)}</p>
      {/* Key forces rerendering when content changes - required otherwise useEditor caches content */}
      <RichTextDisplay content={body}/>
      <PdfDisplayer pdfUrls={attachmentURLs} className="mb-3"/>
    </div>
  );
}

import {
  createContext,
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Alert,
  Breadcrumb, Button, Col, InputGroup, Row,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import {
  addCommentToAnnouncement,
  getAnnouncementById, replyToComment,
} from '../services/announcement';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import AnnouncementContent from '../components/announcement/contextbound/AnnouncementContent';
import CommentSection from '../components/announcement/contextbound/CommentSection';
import { validateComment } from '../utils/AnnouncementUtils';

const pageMetadata = {
  titleLH: 'Our',
  titleRH: 'Announcements',
  body: 'Don\'t miss any announcements! You need to refresh the page to see new comments.',
};

const AnnouncementContext = createContext({
  id: "",
  forEventCode: "",
  creationDate: "",
  scheduledDate: null,
  title: "",
  body: "",
  comments: [],
  attachmentURLs: [],
  // helper functions
  addComment: (comment) => {},
  addSubComment: (parentComment, subComment) => {}
})

export default function AnnouncementPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  const [loading, withLoading] = useWithLoading();
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState();
  const history = useHistory();

  useEffect(() => {
    withLoading(
      () => getAnnouncementById(
        authRetrievedProfile?.email,
        authRetrievedProfile?.code,
        announcementId,
      ),
      (res) => setAnnouncement(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);


  const addComment = async (comment) => {
    // Check that the comment is not empty
    if (!validateComment(comment)) return;

    const newComment = {
      user: authRetrievedProfile.email,
      name: authRetrievedProfile.name,
      content: comment,
      role: authRetrievedProfile.role,
      creationDate: new Date(),
    };

    try {
      withLoading(
        () => addCommentToAnnouncement(authRetrievedProfile.email, authRetrievedProfile.code, announcementId, newComment),
        (res) => setAnnouncement(res?.data),
        ALERT_ERROR_MESSAGE,
      );
    } catch (e) {
      alert(e);
    }
  };

  const addSubComment = async (parentComment, subComment) => {
    if (!validateComment(subComment)) return;

    const newReply = {
      // Create a temporary id for frontend rendering
      id: Date.now()
        .toString(),
      name: authRetrievedProfile.name,
      user: authRetrievedProfile.email,
      content: subComment,
      role: authRetrievedProfile.role,
      replies: [],
      creationDate: new Date(),
    };

    const parentAndReply = {
      parent: parentComment,
      reply: newReply,
    };

    // show the added reply to user first to 'fake' fast experience
    const updatedAnnouncement = { ...announcement };
    updatedAnnouncement?.comments
      ?.filter(comment => comment.id === parentComment.id)
      ?.forEach(comment => comment.replies.push(newReply));
    setAnnouncement(updatedAnnouncement);

    // Update database with comment.
    await replyToComment(
      authRetrievedProfile?.email,
      authRetrievedProfile?.code,
      announcementId,
      parentAndReply,
    );
  };


  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/announcements')}>
          Announcements
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{announcement?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Loading loading={loading}>
          <AnnouncementContext.Provider value={{...announcement, addComment, addSubComment}}>
            <Col lg={6}>
              <AnnouncementContent />
            </Col>
            <Col lg={6}>
              <CommentSection />
            </Col>
          </AnnouncementContext.Provider>
        </Loading>
      </Row>
    </>
  );
}

export {
  AnnouncementContext
}


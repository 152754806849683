import { useContext, useEffect } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { Section } from '../components/forms/common/FormComponents';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import SingleSelectInputFromList from '../components/forms/common/SingleSelectInputFromList';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import SignUpInput from '../components/forms/signup/SignUpInput';
import { ETHNICITY, GENDERS, YEAR_LEVELS } from '../configs';
import { PageContext } from '../context/PageContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { onCreateStudentAsync } from '../services/student';
import { getTeacher } from '../services/teacher';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { toast } from 'react-toastify';

const pageMetadata = {
  titleLH: 'Platform Registration for',
  titleRH: 'Student',
  body: 'Register to our platform to receive and accept invitations to our exclusive competitions and workshops.',
};

export default function StudentSignupPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { session } = useContext(SessionContext);
  const history = useHistory();
  const [loading, withLoading] = useWithLoading();

  const onSubmit = () => withLoading(
    onCreateStudentAsync(session),
    (res) => {
      toast.success("Your account was created successfully!")
      history.push(`/login`)
    },
    ALERT_ERROR_MESSAGE,
  );

  return (
    <>
      <PrettyForm
        submitText="Create Account"
        cancelText="Cancel"
        onSubmit={onSubmit}
        onCancel={() => history.goBack()}
        loading={loading}
      >
        <Row>
          <Alert variant="info">
            After registering, don't forget to register for the specific event(s) you want to participate in on your dashboard!
          </Alert>
        </Row>
        <Row>
          <Col>
            <Section title="1. Personal Details" details="Enter your details. You may omit optional fields.">
              <TextBoxInput label="First Name" field="firstName" />
              <TextBoxInput label="Middle Name (Optional)" field="middleName" required={false} />
              <TextBoxInput label="Last Name" field="lastName" />
              <SingleSelectInputFromList label="Year Level" field="yearLevel" data={YEAR_LEVELS} />
              <Alert variant="warning">
                Your email must be your personal email.
              </Alert>
              <SignUpInput forStudent />
            </Section>
          </Col>
          <Col>
            <Section
              title="2. Demographics"
              details="This information is reserved for data analytics and will not influence your outcome."
            >
              <SingleSelectInputFromList label="Gender" field="gender" data={GENDERS} />
              <SingleSelectInputFromList label="Ethnicity" field="ethnicity" data={ETHNICITY} />
            </Section>
          </Col>
        </Row>
      </PrettyForm>
    </>
  );
}

import { useContext, useEffect, useState } from 'react';
import ErrorPlaceholder from '../components/common/ErrorPlaceholder';
import Dashboard from '../components/events/Dashboard';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext, defaultPageMetadata } from '../context/PageContextProvider';
import {
  ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE,
} from '../utils/roleComputation';
import DisplayForRole from '../components/common/DisplayForRole';
import AdminBackdoor, { GoBackToBeingAnAdmin } from '../components/dashboard/admin/AdminBackdoor';
import { Alert, Button } from 'react-bootstrap';

function DashboardPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    const pageMetadata = { ...defaultPageMetadata };
    if (authRetrievedProfile) {
      pageMetadata.titleLH = 'Welcome';
      pageMetadata.titleRH = authRetrievedProfile?.name;
    }
    setPageMetadata(pageMetadata);
  }, []);

  return (
    <>
      <AdminBackdoor/>
      <GoBackToBeingAnAdmin/>
      <Dashboard />
    </>
  );
}

export default DashboardPage;

import React, { useContext, useState } from 'react';
import { Accordion, Badge, Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContextProvider';
import { Expand, ExpandWrapper } from '../../common/layout/Expand';
import { replyToComment } from '../../../services/announcement';
import { validateComment } from '../../../utils/AnnouncementUtils';
import { AnnouncementContext } from '../../../pages/AnnouncementPage';

export default function Comment({
  comment,
  // only top level comments are reply-able
  topLevelComment: isTopLevelComment
}) {

  const {authRetrievedProfile} = useContext(AuthContext);
  const {name, role, content, replies} = comment

  const NameBadge = () => {
    const roleColour = (role) => (role === 'Admin' ? 'danger' : (role === 'Teacher' ? 'warning' : 'success'));
    return (
      <>
        <div className="fw-bold" style={{
          display: 'inline',
          marginRight: '10px'
        }}>{name}</div>
        <Badge bg={roleColour(role)} style={{ display: 'inline' }}>
          {role}
        </Badge>
      </>
    );
  }

  const SubComments = () => {
    if (!isTopLevelComment) return <></>;
    if (!replies || replies.length === 0) return <></>;

    return (
      <details style={{ margin: '10px' }}>
        <summary className={'text-muted'}>{`${replies.length} replies`}</summary>
        <div>
          {replies.map(reply => <Comment key={reply.id} comment={reply} isTopLevelComment={false}/>)}
        </div>
      </details>
    );
  }

  return (
    <div className="text-truncate p-2 my-1" style={{ border: isTopLevelComment ? '1px solid #ccc' : 'none' }}>
      <div className="ms-2 me-auto" >
        <NameBadge/>
        <div style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>{content}</div>
      </div>
      <SubComments />
      {(isTopLevelComment && authRetrievedProfile) && <ReplyBox comment={comment} />}
    </div>
  );
}

function ReplyBox({ comment }) {
  const [replyContent, setReplyContent] = useState();
  const {addSubComment} = useContext(AnnouncementContext)
  const handleReplyChange = (e) => setReplyContent(e.target.value);

  return (
    <InputGroup className="mt-2">
      <Form.Control
        placeholder="Reply to this comment..."
        value={replyContent}
        onChange={handleReplyChange}
        style={{
          marginLeft: '5px',
          marginRight: '10px',
        }}
      />
      <ButtonGroup>
        <Button variant="outline-secondary" size="sm" onClick={()=>addSubComment(comment, replyContent)}>
          Comment
        </Button>
      </ButtonGroup>
    </InputGroup>
  );
}




import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContextProvider';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import SchoolViewPage from '../components/school/SchoolViewPage';
import JoinSchoolPage from '../components/school/JoinSchoolPage';
import SchoolStudentsView from '../components/school/SchoolStudentsView';
import { Breadcrumb } from 'react-bootstrap';
import { PageContext } from '../context/PageContextProvider';

const pageMetadata = {
  titleLH: '',
  titleRH: 'My School Page',
  body: 'See who is part of NZPMC from your school!',
};

const MySchoolPage = ()=>{
  const {setPageMetadata} = useContext(PageContext)
  useEffect(() => setPageMetadata(pageMetadata), []);
  const {authRetrievedProfile : auth} = useContext(AuthContext);
  console.log('auth', auth)
  const history = useHistory()
  if (!auth) {
    history.push("/login")
  }

  if (!auth?.school) return (<JoinSchoolPage />)
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>My School Page</Breadcrumb.Item>
      </Breadcrumb>
      <SchoolViewPage schoolName={auth.school} />
      <SchoolStudentsView schoolName={auth.school} />
    </>
  )
}



export default MySchoolPage



import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../context/PageContextProvider';
import { useHistory } from 'react-router-dom';
import useWithLoading from '../hooks/useWithLoading';
import { AuthContext } from '../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import Loading from '../components/common/Loading';
import { getEvents } from '../services/events';
import EventCard from '../components/events/EventCard';
import ScrollSpyNavigation from '../components/common/layout/ScrollSpyNavigation';
import { MONTH_LIST } from '../utils/Constants';
import { categoriseByMonths } from '../utils/CategorisationUtils';

const pageMetadata = {
  titleLH: 'NZPMC',
  titleRH: 'Events',
  body: "See all NZPMC events, including competitions and workshops",
}
const EventListPage = ()=>{
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const history = useHistory();

  const [loading, withLoading] = useWithLoading();
  const [events, setEvents] = useState([]);

  const eventsByMonths = categoriseByMonths(events, (event) => event?.eventDate);

  const { authRetrievedProfile:auth } = useContext(AuthContext);

  useEffect(() => {
    withLoading(
      getEvents(auth),
      (res) => setEvents(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Events</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading} message="Retrieving all the events">
        <CategorisedNavigation list={eventsByMonths} />
      </Loading>
    </>
  );
}

const Chunk = ({events})=>{
  return <>{events?.map(((event, index) => <EventCard event={event} index={index + 1}/>))}</>
}


const CategorisedNavigation = ({list}) => {
  return (
    <Row>
      <Col md={12} lg={3}>
        <ScrollSpyNavigation
          list={list}
          labelRenderer={(category) => `${MONTH_LIST[category.month]}, ${category.year}`}
        />
      </Col>
      <Col md={12} lg={9}>
        {list.map(category => <>
          <div className={"lead my-3"} id={category.key}>{MONTH_LIST[category.month]}, {category.year}</div>
          <Chunk events={category.list} />
        </>)}
      </Col>
    </Row>
  );
}

export default EventListPage

import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useWithLoading from '../../../../hooks/useWithLoading';
import { declineEvent, getRegistrationStatus } from '../../../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import Loading from '../../../common/Loading';
import { EventContext } from '../../../../pages/EventViewPage';
import { getRole, STUDENT_ROLE } from '../../../../utils/roleComputation';
import { DECLINED, NOT_REGISTERED, REGISTERED } from '../../../../utils/Constants';

const EventPrimaryActionButton = ()=>{

  const {authRetrievedProfile: auth} = useContext(AuthContext)
  const history = useHistory()
  const {eventCode, registrationDueDate} = useContext(EventContext)

  if(new Date() > registrationDueDate) return null

  if(!auth) return <Button onClick={() => history.push("/login")}>Register Now!</Button>

  if(getRole(auth) !== STUDENT_ROLE) return null

  const [registrationStatus, setRegistrationStatus] = useState();
  const [loading, withLoading] = useWithLoading();

  const fetchEventStatus = () => {
    withLoading(
      getRegistrationStatus(auth, eventCode),
      (res) => setRegistrationStatus(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  };
  useEffect(() => {
    fetchEventStatus();
  }, []);

  return (
    <Loading loading={loading} message={"fetching registration status..."}>
      {registrationStatus !== NOT_REGISTERED && <TakenActionDisplay registrationStatus={registrationStatus}/>}
      {registrationStatus === NOT_REGISTERED && <ActionButtons />}
    </Loading>
  )
}


function TakenActionDisplay({registrationStatus}) {
  return (
    <div className={"fs-5"}>
      {registrationStatus === REGISTERED && <span className={"bi bi-check-circle-fill text-success"}>{" "}You are registered!</span>}
      {registrationStatus === DECLINED && <span className={"bi bi-x-circle-fill text-danger"}>You've declined</span>}
    </div>
  );
}

function ActionButtons(){
  const [loading, withLoading] = useWithLoading();
  const {eventCode} = useContext(EventContext)
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  const history = useHistory()
  const onRegister = () => history.push(`/Payment/${eventCode}`);

  const onDecline = () => {
    if (!window.confirm('Are you sure?')) return;
    if (!window.confirm("Are you really sure? (You can't revert this action)")) return;
    withLoading(
      declineEvent(auth, eventCode),
      // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
      () => history.go(0),
      ALERT_ERROR_MESSAGE,
    );
  };
  return <Loading loading={loading}>
    <div>
      <Button variant={"primary"} className={"me-2"} onClick={onRegister}>
        Register Now!
      </Button>
      <Button variant={"danger"} onClick={onDecline}>
        Decline
      </Button>
    </div>
  </Loading>

}



export default EventPrimaryActionButton

import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../context/SessionContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import {
  getSchoolNames,
  joinSchoolAsTeacher,
  joinSchoolAsStudent
} from '../../services/schools';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import { Alert, Button, Col, InputGroup, Row } from 'react-bootstrap';
import { AutoCompleteTextBoxInput, Section } from '../forms/common/FormComponents';
import SchoolViewPage from './SchoolViewPage';
import { AuthContext } from '../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole, STUDENT_ROLE, TEACHER_ROLE } from '../../utils/roleComputation';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const JoinSchoolPage = () => {
  const { session } = useContext(SessionContext);
  const [schoolNames, setSchoolNames] = useState([]);
  const [loading, withLoading] = useWithLoading();
  const [schoolNameToShow, setSchoolNameToShow] = useState();

  const {authRetrievedProfile: auth} = useContext(AuthContext)
  useEffect(() => {
    withLoading(
      getSchoolNames(),
      res => setSchoolNames(res.data),
      ALERT_ERROR_MESSAGE
    );
  }, []);

  const updateSchoolDisplayed = (e) => setSchoolNameToShow(session['schoolToJoin']);

  return (
    <Loading loading={loading}>
      {getRole(auth) === ADMIN_ROLE && <Alert>A normal user would join school via this page. You are an admin, so you don't get to join it.</Alert>}
      <span className={'display-6'}>
        You are currently <b>not</b> part of a school
      </span>
      <br/>
      <span className={'lead'}>
        By becoming associated with a school, you can see who else from your school joined NZPMC and receive bulk discount.
      </span>
      <br/><br/>
      <Row>
        <Col md={6}>
          <Section title={'1. Search school'} details={"Search a school first before joining it. Once you join a school, you cannot change it."}>
            <InputGroup className="mb-3">
              <AutoCompleteTextBoxInput field={'schoolToJoin'} data={schoolNames}/>
              <Button className={'w-100'} variant={'outline-dark'} onClick={updateSchoolDisplayed}>Search</Button>
            </InputGroup>
          </Section>
        </Col>
        <Col md={6}>
          <Section title={'2. Join School'} details={"By pressing the button below, you become part of the school. You will need to login again."}>
            <JoinSchoolAction schoolName={schoolNameToShow}/>
          </Section>
        </Col>
      </Row>
      <Row>
        <SchoolViewPage schoolName={schoolNameToShow}/>
      </Row>
      <br/>

    </Loading>
  );
};

const JoinSchoolAction = ({ schoolName }) => {
  const [loading, withLoading] = useWithLoading();
  const history = useHistory();
  const JoinAsStudent = () => {
    const {
      authRetrievedProfile: auth,
      onLogOut
    } = useContext(AuthContext);
    if (getRole(auth) !== STUDENT_ROLE) return null;

    return (<>
      <Button className={"me-2"} variant={'outline-dark'} onClick={() => withLoading(
        joinSchoolAsStudent(auth, schoolName),
        async () => {
          await onLogOut();
          history.push('/login');
          toast.success(`Successfully joined ${schoolName}!`);
        },
        ALERT_ERROR_MESSAGE
      )}>
        Join as a student
      </Button>
    </>);
  };

  const JoinAsTeacher = () => {
    const {
      authRetrievedProfile: auth,
      onLogOut
    } = useContext(AuthContext);
    if (getRole(auth) !== TEACHER_ROLE) return null;

    return (<>
      <Button className={"me-2"} variant={'outline-dark'} onClick={() => withLoading(
        joinSchoolAsTeacher(auth, schoolName),
        async () => {
          await onLogOut()
          history.push("/login")
          toast.success(`Successfully joined ${schoolName} as main teacher!`);
        },
        ALERT_ERROR_MESSAGE
      )}>
        Join as a teacher
      </Button>

    </>);
  };

  return (
    <div>
      <Loading loading={loading}>
        <JoinAsStudent/>
        <JoinAsTeacher/>
      </Loading>
    </div>
  );

};
export default JoinSchoolPage;

import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function useSimpleModal(){
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const setModalVisible = () => setShow(true);

  const ModalWrapper = ({ children, title }) =>(
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>)

  return [setModalVisible, ModalWrapper, show]
}


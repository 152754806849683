import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import LinkCard from './LinkCard';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';

const DashboardLinks = ()=>{
  const history = useHistory();
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  return(
    <Row>
      <Col md={6}>
        <LinkCard title={"My School"} onClick={()=>history.push(`/school`)} color={"linear-gradient(124deg, #9ebcff, #8981e8)"}>
          See who's participating!
        </LinkCard>
      </Col>
      <Col md={6}>
        <LinkCard title={"Practice Questions"} onClick={()=> window.open("https://www.nzpmc.com/resources/past-questions", "_blank")}
                  color={"linear-gradient(124deg, #eebcff, #b981e8)"}>
          Practice makes perfect :D
        </LinkCard>
      </Col>
      <Col md={6}>
        <LinkCard title={"FAQ"} onClick={()=> history.push("/faq")} color={"linear-gradient(124deg, #4ebc7f, #55d181)"}>
          New here?
        </LinkCard>
      </Col>
      <Col md={6}>
        <LinkCard title={"Supporters"} onClick={()=> window.open("https://www.nzpmc.com/about-us/sponsors-supporters", "_blank")} color={"linear-gradient(124deg, #b9af6b, #cabf7c)"}>
          See our sponsors~
        </LinkCard>
      </Col>
    </Row>
  )
}
export default DashboardLinks

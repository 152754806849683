import { Children, useContext } from 'react';
import { EventContext } from '../../../pages/EventViewPage';
import { AuthContext } from '../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole } from '../../../utils/roleComputation';
import ReleaseEventButton from './admin/ReleaseEvent';
import AddFormButton from './admin/AddFormButton';
import ViewFormButton from './admin/ViewFormButton';
import EventPrimaryActionButton from './student/EventPrimaryActionButton';
import { Alert } from 'react-bootstrap';
import ResultsCard from './student/ResultsCard';
import CreateAnnouncementButton from './admin/CreateAnnouncementButton';
import TeacherActionButton from './teacher/TeacherActionButton';
import './EventActionGroup.css'

const EventActionGroup = ()=>{
  const common = "d-block fw-bold text-decoration-none lead"
  const studentClassName = `${common}`
  const teacherClassName = `bi bi-people ${common}`
  const adminClassName = `bi bi-person-gear ${common}`

  return (<>
      <small className={"fw-bold text-muted small"}>Actions</small>
      <div className={"event-actions-group"}>
        <div className={"student-actions"}>
          <EventPrimaryActionButton />
          <ResultsCard className={studentClassName}/>
        </div>
        <div className={"teacher-actions"}>
          <TeacherActionButton className={teacherClassName}/>
        </div>
        <div className={"admin-actions"}>
          <CreateAnnouncementButton className={adminClassName}/>
          <ReleaseEventButton className={adminClassName}/>
          <AddFormButton className={adminClassName}/>
          <ViewFormButton className={adminClassName}/>
        </div>
      </div>
    </>
  )
}

export default EventActionGroup;

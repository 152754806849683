import { Alert, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getDateStringFromAnnouncement, getNumberOfComments, removeHtmlTags } from '../../utils/AnnouncementUtils';


export default function AnnouncementList({ announcements, detailedView=false }) {
  if(!announcements || announcements.length === 0) return <Alert variant={"warning"}>There are no announcements for this event yet.</Alert>

  const history = useHistory()

  return (
    <ListGroup>
      {announcements ? announcements?.map((announcement) => {
        return (
          <ListGroup.Item
            key={announcement.id}
            onClick={() => history.push(`/announcements/${announcement.id}`)}
            action
            className="d-flex justify-content-between align-items-start"
          >
            {detailedView && <DetailedAnnouncementItem announcement={announcement} />}
            {!detailedView && <SimpleAnnouncementItem announcement={announcement} />}
          </ListGroup.Item>
        )
      }) : <div>No announcements</div>}
    </ListGroup>
  );
}


function DetailedAnnouncementItem({announcement}) {
  const title = announcement.title
  const numberOfComments = (announcement?.comments && getNumberOfComments(announcement.comments)) || 0
  const numberOfAttachments = announcement.attachmentURLs?.length || 0
  const body = removeHtmlTags(announcement.body).substring(0,500)
  const dateString = getDateStringFromAnnouncement(announcement?.scheduledDate, announcement?.creationDate)
  return (<>
    <div style={{ width: '100%' }}>
      <div className="fw-bold text-truncate d-flex justify-content-between mb-1">
        <div>
          {title}
          {numberOfAttachments !== 0 && <span className="fw-bold small ms-2" style={{ color: "mediumvioletred" }}><i
            className="bi bi-paperclip"></i></span>}
        </div>
        <div>
          {numberOfComments !== 0 && <span className="fw-bold small ms-2" style={{ color: 'darkgreen' }}><i
            className="bi bi-chat"></i> {numberOfComments} replies</span>}
        </div>
      </div>
      <span className={"fw-light text-muted small"}>{dateString}</span>
    </div>
  </>)
}


function SimpleAnnouncementItem({announcement}) {
  const title = announcement.title
  const numberOfComments = (announcement?.comments && getNumberOfComments(announcement.comments)) || 0
  const numberOfAttachments = announcement.attachmentURLs?.length || 0
  const body = removeHtmlTags(announcement.body).substring(0,500)
  return (<>
    <div style={{ width: '100%' }}>
      <div className="fw-bold text-truncate d-flex justify-content-between mb-1">
        <div>
          {title}
          {numberOfAttachments !== 0 && <span className="fw-bold small ms-2" style={{ color: "mediumvioletred" }}><i
            className="bi bi-paperclip"></i></span>}
        </div>
        <div>
          {numberOfComments !== 0 && <span className="fw-bold small ms-2" style={{ color: 'darkgreen' }}><i
            className="bi bi-chat"></i> {numberOfComments} replies</span>}
        </div>
      </div>
    </div>
  </>)
}

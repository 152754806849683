import { Update, UpdateSection } from '../../../pages/ReleaseNotePage';

const January2025Update = () => {
  return <Update month={'January'} version={'1.0.1'} keyTheme={'Flexible registration'} isUpcoming>
    <UpdateSection category={'Registration without school'}>
      <li>You don't need to be associated with a school to register for a competition</li>
    </UpdateSection>
    <UpdateSection category={'NZPMC Coins'}>
      <li>You will be able to prepay NZPMC currency</li>
      <li>In the future, you will be able to transfer NZPMC coins to others, purchase NZPMC goods</li>
    </UpdateSection>
  </Update>;
};

export default January2025Update

import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

const HeaderBuilder = ({addElementToForm}) => {
  const [json, setJson] = useState({})

  return <Form onSubmit={(e) => {
    e.preventDefault()
    json["formInput"] = "header"
    console.log(json)
    addElementToForm(json)
  }}>
    <Form.Group>
      <Form.Label column={"xs"}>Label*</Form.Label>
      <Form.Control required type={"text"} onChange={e=>setJson({...json, label: e.target.value})} />
    </Form.Group>
    <br/>
    <Button type={"submit"}>Append to form</Button>
  </Form>
}

export default HeaderBuilder

import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContextProvider';
import { SessionContext } from '../../../context/SessionContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { authenticate, authenticateAsOtherUser } from '../../../services/authroutes';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import { ADMIN_ROLE, getRole } from '../../../utils/roleComputation';
import DisplayForRole from '../../common/DisplayForRole';
import Loading from '../../common/Loading';
import Form from 'react-bootstrap/Form';
import { Alert, Button, InputGroup } from 'react-bootstrap';

export default function AdminBackdoor() {
  return (
    <DisplayForRole forRole={ADMIN_ROLE}>
      <p className={'text-muted fw-bold'}>Admin tools</p>
      <Alert variant={'info'}>
          <GoToDifferentProfile/>
          <OtherActions />
      </Alert>
    </DisplayForRole>
  );
}

function GoToDifferentProfile() {
  const {
    authRetrievedProfile,
    setAuthRetrievedProfile
  } = useContext(AuthContext);
  const { handleSessionChange } = useContext(SessionContext);
  const [loading, withLoading] = useWithLoading();
  const [targetEmail, setTargetEmail] = useState('');
  const history = useHistory();

  const setCurrentAuthProfileToUser = () => {
    const actuallyIAmAdmin = {
      email: authRetrievedProfile.email,
      code: authRetrievedProfile.code,
    };

    withLoading(
      authenticateAsOtherUser(authRetrievedProfile, targetEmail),
      (res) => {
        handleSessionChange('code', false, true)(res?.data.code);
        handleSessionChange('email', false, true)(targetEmail);
        setAuthRetrievedProfile({
          ...res?.data,
          actuallyIAmAdmin,
        });
        // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
        history.go(0);
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  return (
    <Loading loading={loading} message="hacking...">
      <DisplayForRole forRole={ADMIN_ROLE}>
        <span>Login to any email address!</span>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Email to login to"
            onChange={(e) => setTargetEmail(e.target.value)}
            style={{ width: '50%' }}
          />
          <Button type="submit" onClick={setCurrentAuthProfileToUser}>go!</Button>
        </InputGroup>
      </DisplayForRole>
    </Loading>
  );
}

export function GoBackToBeingAnAdmin() {
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile, setAuthRetrievedProfile } = useContext(AuthContext);
  const history = useHistory();

  const onClick = () => {
    const adminProfile = authRetrievedProfile?.actuallyIAmAdmin;
    withLoading(
      authenticate({ ...adminProfile }),
      (res) => {
        setAuthRetrievedProfile({
          ...res?.data,
          email: adminProfile.email,
          code: adminProfile.code,
        });
        // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
        history.go(0);
      },
      ALERT_ERROR_MESSAGE,
    );
  };
  if (authRetrievedProfile?.actuallyIAmAdmin?.email.length > 0) {
    return (
      <Loading loading={loading} message="hacking...">
        <Button variant={"outline-dark"} className="mb-3" onClick={onClick}>
          i want to go back to being an admin ㅜ. ㅜ
        </Button>
      </Loading>
    );
  }
  return <></>;
}

function OtherActions(){
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  if(getRole(auth) !== ADMIN_ROLE) return <></>

  const history = useHistory()
  return (
    <>
      <span className={'me-5'}>Other actions</span><br/>
      <Button className={'me-2 mb-2 d-block d-md-inline'} variant={'secondary'} onClick={() => history.push('/admin/createEvent')}>Create
        Event
      </Button>
      <Button className={"me-2 mb-2 d-block d-md-inline"} variant={'secondary'} onClick={() => history.push('/admin/createAnnouncement')}>Create Announcement
      </Button>
      <Button className={"me-2 mb-2 d-block d-md-inline"} variant={'secondary'} onClick={() => history.push('/admin/updateYearLevel')}>Update student year
        level</Button>
    </>
  )
}

import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

const SelectOneBuilder = ({addElementToForm}) => {
  const [json, setJson] = useState({})

  return <Form onSubmit={(e) => {
    e.preventDefault()
    json["formInput"] = json.required ? "required-select-one" : "select-one"
    json["data"] = json.data || []
    console.log(json)
    addElementToForm(json)
  }}>
    <Form.Group>
      <Form.Label column={"xs"}>Label*</Form.Label>
      <Form.Control required type={"text"} onChange={e=>setJson({...json, label: e.target.value})} />
    </Form.Group>
    <Form.Group>
      <Form.Label column={"xs"}>Spreadsheet column*</Form.Label>
      <Form.Control required type={"text"} onChange={e=>{
        setJson({...json, field: e.target.value})
      }} />
    </Form.Group>
    <Form.Group>
      <Form.Label column={"xs"}>List (Eg. "Vegetarian,Vegan,None of the above")</Form.Label>
      <Form.Control type={"text"} onChange={(e) => setJson({...json, data:e.target.value.split(',')})} />
    </Form.Group>
    <br/>
    <Form.Check
      type="checkbox"
      label={"Enforce user input?"}
      onChange={e => setJson({...json, required: e.target.checked})}
    />
    <br/>
    <Button type={"submit"}>Append to form</Button>
  </Form>
}

export default SelectOneBuilder
